$( document ).on('turbolinks:load', function() {
  const $qrCodeInput = $('.sub-qr-code-input');
  const $qrCodePreview = $('#sub-qr-code-preview');

  // Function to update the QR code preview
  function updateQrCodePreview() {
    const qrCodeValue = $qrCodeInput.val();

    if (qrCodeValue) {
      $qrCodePreview.show();
      $qrCodePreview.text(`${window.location.origin}/tickets/new?qr_code=${qrCodeValue}`);
    } else {
      $qrCodePreview.hide();
    }
  }

  // Event listener for QR code input change
  $qrCodeInput.on('input', function() {
    updateQrCodePreview();
  });

  updateQrCodePreview();
})