$(document).on('turbolinks:load', function () {
    function number_of_tickets(){
        tickets_count = parseInt($("#raffle_transaction_booked_tickets").val())
        total_amount = tickets_count*5
        buy_tickets = tickets_count
        extra_tickets = parseInt(tickets_count/4) * 2
        total_tickets = extra_tickets + buy_tickets
        $('#total-amount').text("$" + total_amount.toString())
        $('#buy-tickets').text(buy_tickets.toString())
        $('#extra-tickets').text(extra_tickets.toString()+" "+"Extra")
        $('#total-tickets').text(total_tickets.toString())
    }
    setTimeout(function() {  number_of_tickets(this) }, 300);
    $('#raffle_transaction_booked_tickets').on('change', function () { number_of_tickets() });
});