import "components/nav"
$( document ).on('turbolinks:load', function() {
    $('#raffle_patner_id').on('change', function() {
        var patner_id = this.value
        if (patner_id == "") {
            set_values_in_raffles_fields("")
            return
        }
        $.ajax({
            method: "GET",
            url: `/admin/partners/${patner_id}/get_patner_whole_address`,
            data: {},
            success: function(response){
                show_flash("success", response.message)
                set_values_in_raffles_fields(response)
            },
            error: function(response){
                show_flash("warning", "Unsuccessful to get data of selected patner")
            },
        });
    });
    function set_values_in_raffles_fields(response){
        $('#raffle_address').val(response.address || "")
        $('#raffle_city').val(response.city || "")
        $('#raffle_state').val(response.state || "")
        $('#raffle_zipcode').val(response.zipcode || "")
    }
    $("#raffle_is_active").click(function() {
        var i, is_acttive;
        is_acttive = this.checked? false : true
        if (is_acttive){i = confirm("Are you sure? you want to deactivate");}
        else {i = confirm("Are you sure? you want to activate");}
        if(i == true){ $(this).prop('checked', this.checked ); }
        else{ $(this).prop('checked', !this.checked ); }
    });
});


$(document).on('turbolinks:load', function() {
    const dropdown = document.getElementById('raffleFilterDropdown');
    if (!dropdown){
        return
     }
    dropdown.addEventListener('change', (event) => {
        const selectedValue = event.target.value;
        const url = new URL(window.location.href);
        const params = new URLSearchParams();
        if (selectedValue){
            params.set('active', selectedValue);
        }
        url.search = params.toString();
        window.location.href = url.toString();
    });
});

$(document).on('turbolinks:load', function() {
    function handleSections (data, dataIcon){
        if (data.css('display') === 'none') {
            data.show();
            dataIcon.removeClass("fa-circle-plus").addClass("fa-circle-minus");
          } else {
            data.hide();
            dataIcon.removeClass("fa-circle-minus").addClass("fa-circle-plus");
          }
    }

    $('.details-collapse').on('click', function() {
      var details = $('#details-section');
      var detailsCircle = $('#details-circle');
      handleSections(details, detailsCircle)
    });

    $('.expenses-collapse').on('click', function() {
        var expenses = $('#expenses-section');
        var expensesCircle = $('#expenses-circle');
        handleSections(expenses, expensesCircle)
    });

    $('.arts-collapse').on('click', function() {
        var expenses = $('#arts-section');
        var expensesCircle = $('#arts-circle');
        handleSections(expenses, expensesCircle)
    });
});  

$(document).on('turbolinks:load', function() {

  function updateExpenseTotals() {
    let permanentTotal = 0;
    let redeemableTotal = 0;
    let totalExpense = 0;
  
    $(".expense-count").each(function() {
      let count = parseInt($(this).val()) || 0;
      let price = parseFloat($(this).attr('data-price')) || 0;
      let type = $(this).attr('data-expense-type');
      let subTotal = count * price;
  
      // Format subTotal to 2 decimal places
      let formattedSubTotal = subTotal.toFixed(2);
  
      $(this).closest('.expense-div').find('.single-expense-total').text(`${formattedSubTotal}$`);
  
      if (type === 'Permanent Expense') {
        permanentTotal += subTotal;
      } else if (type === 'Redeemable Expense') {
        redeemableTotal += subTotal;
      }
  
      totalExpense += subTotal;
    });
  
    // Format totals to 2 decimal places
    $('.permanent-expense strong').text(`${permanentTotal.toFixed(2)}$`);
    $('.redeemable-expense strong').text(`${redeemableTotal.toFixed(2)}$`);
    $('.total-expense strong').text(`${totalExpense.toFixed(2)}$`);
  }
  
  // Function to add a new expense div with input value of 0
  function addExpenseDiv(expenseId, price, type, name) {
    const divHtml = `
      <div class="d-flex gap-3 align-items-center mt-2 expense-div" data-expense-id="${expenseId}">
        <span><i class="fa-solid fa-trash delete-expense-icon"></i></span>
        <span class="single-expense-total">0$</span>
        <input type="number" class="form-control expense-count pe-0 ps-1" 
               data-expense-id="${expenseId}" data-price="${price}"
               data-expense-type="${type}" value="0" min="0">
        <label class="m-0 ms-2 expense-label">${name}</label>
      </div>
    `;
    $('#expenses-section .all-expenses').before(divHtml);
  }

  // When checkbox is checked/unchecked
  $(".expense-checkbox").on("change", function() {
    const expenseId = $(this).closest('td').attr('data-expense-id');
    const price = $(this).closest('td').attr('data-expense-price');
    const type = $(this).closest('td').attr('data-expense-type');
    const name = $(this).closest('td').attr('data-expense-name');

    if ($(this).is(":checked")) {
      addExpenseDiv(expenseId, price, type, name);
    } else {
      $(`.expense-div[data-expense-id="${expenseId}"]`).remove();
    }

    updateExpenseTotals();
  });

  // Handle delete icon click
  $(document).on("click", ".delete-expense-icon", function() {
    if (confirm('Are you sure you want to delete this item?')) {
      const $expenseDiv = $(this).closest('.expense-div');
      const expenseId = $expenseDiv.attr('data-expense-id');
      $expenseDiv.remove();
      $(`#updateExpensesModal input[data-expense-id="${expenseId}"]`).prop('checked', false);

      updateExpenseTotals();
    }
  });

  $(document).on("input", ".expense-count", function() {
    const expenseId = $(this).attr("data-expense-id");
    const price = parseFloat($(this).attr("data-price"));
    const name = $(this).next(".expense-label").text();
    const type = $(this).attr("data-expense-type");
    const count = parseInt($(this).val(), 10) || 0;

    updateExpenseTotals();
  });

  updateExpenseTotals();
});

$(document).on('turbolinks:load', function() {
  function handleExpenseUpdatedData(updates){
    var raffleId = $(".btn-update-expenses").data('raffleId');
      $.ajax({
        url: '/admin/expenses/handle_raffle_expenses',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ expenses: updates, raffle_id: raffleId }),
        success: function(response){
          console.log(response);
            show_flash("success", response.message)
        },
        error: function(response){
          show_flash("warning", "Something went wrong during update")
        },
      });
    }

  $('.btn-update-expenses').on('click', function() {
    var updates = [];
    $(".expense-count").each(function() {
      var expenseId = $(this).data('expenseId');
      var count = parseInt($(this).val(), 10);
      if (count > 0) {
        updates.push({
          expense_id: expenseId,
          count: count
        });
      }
    });
    handleExpenseUpdatedData(updates)
  });

});


$(document).on('turbolinks:load', function() {
  $('.add-art').on('click', function() {
    const artType = $(this).attr('data-art-type');
    const artId = $(this).attr('data-art-id');
    const artName = $(this).attr('data-art-name');
    const artImageUrl = $(this).attr('data-art-image-url');
    const artSize = $(`#${artId}`).val(); 

    let currentContainer, otherContainer;

    if (artType === 'produced_artwork') {
      currentContainer = $('.produced-content');
      otherContainer = $('.re-purposed-content');
    } else {
      currentContainer = $('.re-purposed-content');
      otherContainer = $('.produced-content');
    }

    otherContainer.find(`div[data-art-id="${artId}"]`).remove();
    let existingArt = currentContainer.find(`div[data-art-id="${artId}"]`);
    
    if (existingArt.length > 0) {
      existingArt.replaceWith(generateArtDiv(artId, artName, artSize, artImageUrl, artType));
    } else {
      $(generateArtDiv(artId, artName, artSize, artImageUrl, artType)).insertBefore(currentContainer.find('button'));
    }
  });

  $('.inventory-popup').on('click', function() {
    const artType = $(this).data('art-type');
    $('.add-art').removeAttr('data-art-type');
    $('.add-art').attr('data-art-type', artType);
  });
  
  $(document).on('click', '.delete-icon', function() {
    if (confirm('Are you sure you want to delete this item?')) {
      $(this).closest('.show-img').remove();
    }
  });

  function generateArtDiv(artId, artName, size, imageUrl, artType) {
    return `<div class="mb-0 show-img" data-art-id="${artId}" data-size="${size}" data-img-src="${imageUrl}" data-art-type="${artType}">
              <span><i class="fa-solid fa-trash delete-icon"></i></span>
              <p class="p-link" data-img-src="${imageUrl}" >
              ${size} ${artName}
              </p>
            </div>`;
  }
});

$(document).on('turbolinks:load', function() {
  // Filter Artworks based on the search input
  $('#art-search-input').on('input', function() {
    var searchTerm = $(this).val().toLowerCase();
    // Loop through each art div and hide or show based on search term
    $('.art-div-popup').each(function() {
        var artName = $(this).attr('data-art-name'); // Get art-name from the data attribute
      if (artName && artName.toLowerCase().includes(searchTerm)) {
        $(this).show(); // Show matching items
      } else {
        $(this).hide(); // Hide non-matching items
      }
    });
  });
});

$(document).on('turbolinks:load', function() {
  
    function handleArtsUpdatedData(updates){
      const raffleId = $(".btn-update-art").data('raffleId');
      $.ajax({
        url: '/admin/arts/handle_raffle_arts',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({ arts: updates, raffle_id: raffleId }),
        success: function(response){
          show_flash("success", response.message)
        },
        error: function(response){
          show_flash("warning", "Something went wrong during update")
        },
      });
    }
  
    $('.btn-update-art').on('click', function() {
      const updates = [];
      $('.produced-content div, .re-purposed-content div').each(function() {
        const artId = $(this).attr('data-art-id');
        const size = $(this).attr('data-size');
        const artType = $(this).attr('data-art-type');

        updates.push({
          art_id: artId,
          size: size,
          art_type: artType
        });
      });
  
      handleArtsUpdatedData(updates)
    });
  
  });
