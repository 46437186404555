// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@popperjs/core")
import "bootstrap"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "components/flash"
import "components/nav"
import "admin/raffles"
import "admin/patners"
import "admin/sub_partners"
import "admin/tracking_qr_codes"
import "admin/admin"
import "./theme"
import "users/raffle_transactions"
Rails.start()
Turbolinks.start()
ActiveStorage.start()

$(document).on('turbolinks:load', function () {
  // Handle click on '.p-link' to create and show the image overlay
  $(document).on('click', '.p-link', function(){
    const src = $(this).attr('data-img-src');

    // Remove any existing overlay first
    $('.image-overlay').remove();

    // Create the overlay div
    $('<div class="image-overlay">').css({
        background: `RGBA(0,0,0,0.5) url('${src}') no-repeat center center`,
        backgroundSize: 'contain',
        width: '100%', 
        height: '100%',
        position: 'fixed',
        zIndex: '10000',
        top: '0', 
        left: '0',
        cursor: 'zoom-out'
    }).appendTo('body');
  });

  // Handle click on overlay to remove it
  $(document).on('click', '.image-overlay', function() {
    $(this).remove();
  });
});