$(document).on('turbolinks:load', function() {
  const $artSelect = $('#art_inventory_select');
  const $artUrlField = $('#art_url_field');

  function handleArtChange(){
    const $selectedOption = $artSelect.find('option:selected');
    const artUrl = $selectedOption.data('art-url');
    
    if (artUrl) {
      $artUrlField.val(artUrl);
    } else {
      $artUrlField.val('');
    }
  }

  $artSelect.on('change', function() {
    handleArtChange()
  });
  handleArtChange()
});

$( document ).on('turbolinks:load', function() {

  const $qrCodeInput = $('.tra-qr-code-input');
  const $qrCodePreview = $('#tra-qr-code-preview');
  const $artSelect = $('#art_inventory_select');

  // Function to update the QR code preview
  function updateQrCodePreview() {
    const $artUrlField = $('#art_url_field');
    const artURL = $artUrlField.val();
    const qrCodeValue = $qrCodeInput.val();

    if (qrCodeValue) {
      $qrCodePreview.show();
      $qrCodePreview.text(`${artURL}?qr_code=${qrCodeValue}`);
    } else {
      $qrCodePreview.hide();
    }
  }

  // Event listener for QR code input change
  $qrCodeInput.on('input', function() {
    updateQrCodePreview();
  });

  $artSelect.on('change', function() {
     setTimeout(function() { updateQrCodePreview() }, 100);
  });

  updateQrCodePreview();
})

$(document).on('turbolinks:load', function() {
  $('#search-button').on('click', function() {
    var searchName = $('#search-name').val().toLowerCase();
    var searchLocation = $('#search-location').val().toLowerCase();
    var searchTag = $('#search-tag').val().toLowerCase();

    $('table tbody tr').each(function() {
      var name = $(this).find('td:nth-child(1)').text().toLowerCase();
      var location = $(this).find('td:nth-child(2)').text().toLowerCase();
      var tag = $(this).find('td:nth-child(4)').text().toLowerCase();

      var showRow = true;

      if (searchName && name.indexOf(searchName) === -1) {
        showRow = false;
      }
      if (searchLocation && location.indexOf(searchLocation) === -1) {
        showRow = false;
      }
      if (searchTag && tag.indexOf(searchTag) === -1) {
        showRow = false;
      }

      if (showRow) {
        $(this).show();
      } else {
        $(this).hide();
      }
    });
  });
});
