$( document ).on('turbolinks:load', function() {
    const hide_flash = () => {
        setTimeout(function() { 
            $(".flash").remove();
        }, 5000);
    }
    hide_flash()
    window.show_flash = (type, message) => {
        $("#all-flashes").append(`<div style='margin-top: 20px;' class='flash alert alert-${type}'>${message}</div>`)
        hide_flash()
    }
});