$( document ).on('turbolinks:load', function() {
  $('.patner-image').on('change', function(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = function(e) {
        // Check if the image tag already exists
        if ($('.patner-img').length) {
          // Update the existing image source
          $('.patner-img').attr('src', e.target.result);
        } else {
          // Create a new img element if it doesn't exist
          $('.patner-img-div').html('<img src="' + e.target.result + '" class="patner-img mb-3" style="width: 300px; height: auto" />');
        }
      };

      reader.readAsDataURL(file); // Convert the file to a data URL
    }
  });
})